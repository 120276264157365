import React, { useEffect, useState } from 'react';

import CoalListItem from './BeerListItem';

const CoalList: React.FC = () => {
  const [coalList, setCoalList] = useState<any>([]);

  useEffect(() => {
    fetch('/coal-stock.json')
      .then((response) => response.json())
      .then((json) => setCoalList(json));
  }, []);

  if (coalList.length) {
    const date = new Date(coalList[0]?.updatedAt).toLocaleString('pl-PL');
    return (
      <>
        <h1>Aktualna dostępność węgla ze strony PGG:</h1> <br />
        <p>Data ostatniej aktulizacji: {date}</p>
        <table className="table table-striped table-borderless">
          <thead>
            <tr>
              <th scope="col">Nazwa</th>
              <th scope="col">Dostępność</th>
              <th scope="col">Kopalnia</th>
            </tr>
          </thead>
          <tbody>
            {coalList.map((item: any, index: React.Key | null | undefined) => (
              //@ts-ignore
              <CoalListItem key={index} {...item} />
            ))}
          </tbody>
        </table>
      </>
    );
  }

  return <h2>Ładowanie...</h2>;
};

export default React.memo(CoalList);
