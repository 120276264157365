import React from 'react';
import './BeerList.css';

interface ListItemProps {
  productName: string;
  productUrl: string;
  stockStatus: string;
  price: string;
  mine: string;
  updatedAt: string;
}

const ListItem = ({
  productName,
  productUrl,
  stockStatus,
  price,
  mine,
  updatedAt,
}: ListItemProps) => {
  const colorText = stockStatus === 'Brak towaru' ? 'text-danger' : 'text-success';
  return (
    <tr>
      <td className="text-dark">
        <a target="_blank" href={`https://sklep.pgg.pl/${productUrl}`} rel="noreferrer">
          {productName}
        </a>
      </td>
      <td className={colorText}>
        <strong>{stockStatus}</strong>
      </td>

      <td className="text-dark">{mine}</td>
    </tr>
  );
};

export default ListItem;
