import React from 'react';

import 'bootstrap/dist/css/bootstrap.css';
import BeerPuller from 'pages/BeerPuller';

function App() {
  return (
    <div className="App">
      <BeerPuller />
    </div>
  );
}

export default App;
